import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

export function roleInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  if (!localStorage.getItem('tokens')) {
    return next(req);
  }

  const tokens = JSON.parse(localStorage.getItem('tokens')!);
  const idToken = JSON.parse(atob(tokens.idToken.split('.')[1]));
  const role = idToken['custom:role'];
  const tenantId = idToken['custom:tenantId'];
  if (!tenantId) {
    localStorage.removeItem('tokens');
    window.location.reload();
  }

  return next(req.clone({
    headers: req.headers
      .append('x-role', role)
      .append('x-tenant-id', tenantId),
  }));
}
